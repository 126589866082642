import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Affix, Button } from "antd";
import Media from "react-media";

import * as Const from "../../common/const";

import MenuMain from "./menuMain";
import SiderBase, { siderService } from "../../common/siderBase";
import Sider from "./sider";

import { Popup } from "../../common/component";
import { HomeContentService } from "../content/contentService";
import { FileService } from "../file/fileService";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainbarAffix: "",
      siteInfo: {
        imageId: null,
        name: "",
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    Popup.spin.show();
    HomeContentService.getList({
      idList: [6],
      hasDescription: false,
    }).then((res) => {
      Popup.spin.hide();
      if (!res.success) return;

      this.setState({
        siteInfo: res.itemList[0],
      });
    });
  };

  mainbarAffixChange = (affixed) =>
    this.setState({ mainbarAffix: affixed ? "affix" : "un-affix" });

  render() {
    const { mainbarAffix, siteInfo } = this.state;

    return (
      <header className="header">
        <Media query={Const.media.largeQuery} />
        <Affix
          onChange={this.mainbarAffixChange}
          offsetTop={-100}
          className="ant-affix-wrapper"
        >
          <div className={"mainbar " + mainbarAffix}>
            <div className="container">
              <div className="left">
                <Media
                  query={Const.media.smallQuery}
                  render={() => (
                    <a
                      className="sider-button"
                      onClick={() => {
                        siderService.visible();
                      }}
                    >
                      <i className="fal fa-bars"></i>
                    </a>
                  )}
                />
              </div>
              <div className="center">
                <Link to="/" className="site-logo">
                  <img
                    style={{ borderRadius: "60px" }}
                    src={siteInfo.imageId ? FileService.imageURL(siteInfo.imageId, 512) : ""}
                    alt={siteInfo.name}
                  />
                </Link>
                <a href={"tel:" + siteInfo.summary}>
                  <Button className="ant-btn-primary">
                    <i className="fas fa-phone-alt"></i> Bấm gọi
                  </Button>{" "}
                </a>
              </div>
              <div className="right">
                <Media
                  query={Const.media.largeQuery}
                  render={() => <MenuMain />}
                />
              </div>
            </div>
          </div>
        </Affix>
        <SiderBase colapse>
          <Sider />
        </SiderBase>
      </header>
    );
  }
}
