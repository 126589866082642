import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

import * as Const from "../../common/const";

import { Popup, Msg } from "../../common/component";
import { HomeContentService } from "../content/contentService";
import { ContactService } from "../user/userService";

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: null,
      email: null,
      title: null,
      content: null,
    };

    this.reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    HomeContentService.getList({ idList: [2], hasDescription: true }).then(
      (res) => {
        if (!res.success) return;

        this.setState({
          contactInfo: res.itemList[0],
        });
      }
    );
  }

  onSubmit = async () => {
    const token = await this.reCaptchaRef.current.executeAsync();
    Popup.spin.show();

    let { title } = this.state;
    if (title) title += " - " + Const.domainName;

    const res = await ContactService.submit({
      fullName: this.state.fullName,
      email: this.state.email,
      title,
      content: this.state.content,
      recaptchaToken: token,
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
    } else {
      this.reCaptchaRef.current.reset();
    }
    this.setState({ msgs: res.msgs });
  };

  render() {
    return (
      <section className="contact-page">
        <div className="container">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1FaiW2L3sV0_-S_aoteBPbczA7ZRuymgc&hl=en"
              width="100%"
              height="480"
            ></iframe>
          </div>
          <Row>
            <Col xs={24} md={12}>
              {this.state.contactInfo && (
                <div className="contact-info">
                  <h1>Thông tin liên hệ</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.contactInfo.description,
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}
