import React, { Component } from "react";
import { Row, Col, Modal, Button } from "antd";

import { Popup } from "../../common/component";
import { HomeContentService } from "../content/contentService";
import { FileService } from "../file/fileService";

export default class Home extends Component {
  state = {
    content: "",
    openHour: "",
    notification: "",
    wayGo: "",
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    Popup.spin.show();
    HomeContentService.getList({
      idList: [1, 3, 4, 5],
      hasDescription: true,
    }).then((res) => {
      if (res.success) {
        this.setState({
          wayGo: res.itemList[0],
          notification: res.itemList[1],
          openHour: res.itemList[2],
          content: res.itemList[3],
        });
      }
      Popup.spin.hide();
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="introduce-featured">
          <div className="overlay overlay-bg"></div>
          <div className="container">
            <div className="home-title justify-content-center">
              <div className="home-description">
                <div
                  className="title-main"
                  dangerouslySetInnerHTML={{
                    __html: this.state.content.description,
                  }}
                ></div>
                <div className="title-sub">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.summary,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-info">
            <Row>
              <Col xs={24} md={8} className="open-hour-wrap">
                <div className="info-box">
                  <div className="overflow-text">
                    <div className="title">
                      <i className="far fa-clock"></i>&nbsp;&nbsp;Giờ mở cửa
                    </div>
                    <div
                      className="date-list"
                      dangerouslySetInnerHTML={{
                        __html: this.state.openHour.description,
                      }}
                    >
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={16} className="notify-wrap">
                <div className="info-box">
                  <div className="overflow-text">
                    <div className="title">
                      <i className="fal fa-bell-on"></i>&nbsp;&nbsp;Thông báo
                    </div>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: this.state.notification.description,
                      }}
                    >
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="way-go">
          <Row className="align-items-center">
            <Col xs={24} md={14}>
              <div className="info-box">
                <div className="title">
                  <i className="fal fa-map-marker-check"></i>&nbsp;&nbsp;Địa chỉ
                </div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.wayGo.description,
                  }}
                ></div>
              </div>
            </Col>
            <Col xs={24} md={10} className="text-align-center">
              <img
                className="image-way-go"
                src={this.state.wayGo.imageId ? FileService.imageURL(this.state.wayGo.imageId, 1024) : ""}
                alt={this.state.wayGo.name}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: "20px" }}></div>
        </section>
      </React.Fragment>
    );
  }
}
