import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import * as Const from "../../common/const";
import { MenuService, HomeContentService, ContentURL } from "../content/contentService";

export default class Footer extends Component {
	state = {
		categoryList: [],
		contactInfo: {},
	};

	componentDidMount() {

		HomeContentService.getList({ idList: [2], hasDescription: true }).then((res) => {
			if (!res.success) return;

			this.setState({
				contactInfo: res.itemList[0],
			});
		});
	}

	render() {
		const { categoryList, contactInfo } = this.state;

		return (
			<footer className="footer">
				<div className="container">
					<Row>
						<Col xs={24} sm={24} md={{ span: 12, offset: 2 }} >
							<div className="footer-menu">
								<div className="title">
									<i class="far fa-globe"></i>
									THÔNG TIN
								</div>
								{contactInfo && (
									<div dangerouslySetInnerHTML={{ __html: contactInfo.description }} />
								)}
							</div>
						</Col>
						<Col xs={24} sm={24} md={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 0 }}>
							<div className="footer-menu">
								<div className="title">Nội dung</div>
								{ categoryList && 
									categoryList.map((item) => (
									<Link key={item.id} to={ContentURL.contentListURL({}, { categoryName: item.name, categoryId: item.id })}>
										<span>{item.name}</span>
									</Link>
									))
								}
								<Link to="/">
										<span>Trang chủ</span>
								</Link>
								<Link to="/lien-he">
										<span>Liên hệ</span>
								</Link>
							</div>
						</Col>
					</Row>
				</div>
				<div className="copyright">Copyright by lhthienan.vn. All rights reserved.</div>
			</footer>
		);
	}
}
